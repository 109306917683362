* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.html {
  scroll-behavior: smooth;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/sofyan_city.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about {
  background-color: #eeeae9;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

#about {
    padding-top: 3rem;
}

.about-background {
  background-image: url('/images/sofyan_fountain.jpeg');
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(3px);
}

.about_header {
    background: #000;
    color: #fff;
    padding-top: 2rem;
}

.about_section {
    display: flex;
    background-color: black;
    height: 65vh;
    color: white;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
  .about_section {
      overflow-y: scroll;
  }
}

.about_card {
    background-color: white;
    border-radius: 5%;
    width: 50%;
    height: 10vh;
}

.about__grid__container {
    max-width: 1120px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10rem;
    row-gap: 5rem;
    padding: 10px;
    margin: 10px;
}

@media only screen and (max-width: 768px) {
  .about__grid__container {
    display: grid;
    grid-template-columns: 1fr;
    padding: 1em;
    grid-row-gap: 30px;
    overflow-y: scroll;
    height: 90%;
  }
}

.info_card_main {
    border: 1px solid white;
    padding: 1rem;
    max-width: 1120px;
}

.info_card_text {
    font-size: 1.3rem;
    padding: 5px;
    font-style: italic;
}

.home-background {
  position: absolute;
  background-image: url('/images/sofyan_city.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(3px);
}

.contact_header {
    color: #000;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.contact_main_container {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 80%;
    color: black;
    align-items: center;
    justify-content: space-between;
}

.contact_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
}

.contact_autobio_container {
    display: flex;
    justify-content: center;
    align-content:center;
}

@media only screen and (max-width: 768px) {
  .contact_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow-y: scroll;
    width: 90%;
  }
  .contact_container .contact_autobio {
      max-height: 200px;
      max-width: 100%;
  }
  .contact_autobio_container p{
      font-size: 17px;
  }
}

.contact_autobio {
    font-size: 20px;
    max-width: 90%;
}

.contact_pfp img{
    border-radius: 50%;
    height: 20vh;
    width: auto;
}

.contact_email_socials {
    font-size: 22px;
    text-decoration: underline;
}

.contact__me {
    border: 1px solid black;
    padding: 1rem;
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* max-width: 100%; */
}
